import quizQuestion from './../htmlpolls/quizQuestion.html'
import quizSelectOption from './../htmlpolls/quizSelectOption.html'
import quizAnswer from './../htmlpolls/quizAnswer.html'
import quizOptionResult from './../htmlpolls/quizOptionResult.html'
import IntroSlide from './../htmlpages/IntroSlide.html'
import AuthorIntro from './../htmlpages/AuthorIntro.html'
import FinalScore from './../htmlpages/FinalScore.html'
export function GetHTMLImport(name) {

    switch (name) {
        case 'quizQuestion.html':
            return quizQuestion
            break
        case 'quizSelectOption.html':
            return quizSelectOption
            break
        case 'quizAnswers.html':
            return quizAnswer
            break
        case 'quizOptionResult.html':
            return quizOptionResult
            break
        case 'IntroSlide.html':
            return IntroSlide
            break
        case 'AuthorIntro.html':
            return AuthorIntro
            break
        case 'FinalScore.html':
            return FinalScore
            break
        default:
            throw new Error("Did not find imported HTML in html-imports.js for " + name)
    }
}