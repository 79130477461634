import { HtmlOverlayAnimationComponent } from "./html-overlay-animation-component"
import './../../css/quiz.css'

export class QuizQuestionComponent extends HtmlOverlayAnimationComponent {
    constructor(itemMetadata) {
        super(itemMetadata)
        this.quizId = itemMetadata.quizId
        this.pollPreviouslySubmitted = false
    }


    TweenIn() {
        if (!document.querySelector("video").classList.contains("shrinked")) {
            document.querySelector("video").classList += " shrinked";
        }
        super.TweenIn()
        var questionNumberDiv = this.div.querySelector(".questionNumber")
        var questionTextDiv = this.div.querySelector(".questionText")
        var questionOptionsOrderedList = this.div.querySelector(".questionOptionsOrderedList")
        var quizOptionsContentDiv = this.div.querySelector(".quizQuestionContent")
        quizOptionsContentDiv.style.display = "none";
        var quizQuestionLoadContainer = this.div.querySelector(".quizQuestionLoadContainer")
        quizQuestionLoadContainer.style.display = "flex";
        questionNumberDiv.innerHTML = "";
        var that = this;
        fetch("https://interactive-eular.com/api/get-quiz-data-from-id?Id=" + this.quizId).then(response => response.json()).then((data) => {
            var spacePos = data.question.indexOf(' ');
            questionNumberDiv.innerHTML = "Question " + data.question.slice(0, spacePos);
            questionTextDiv.innerHTML = data.question.slice(spacePos, data.question.length);
            for (var i = 0; i < data.Options.length; i++) {
                questionOptionsOrderedList.innerHTML += "<li>" + data.Options[i] + "</li>"
            }
            quizQuestionLoadContainer.style.display = "none";
            quizOptionsContentDiv.style.display = "flex";
        });


    }

    TweenOut(SetWasDismissedByApplication) {
        if (document.querySelector("video").classList.contains("shrinked")) {
            document.querySelector("video").classList.remove("shrinked");
        }
        super.TweenOut(SetWasDismissedByApplication)
    }
    setCookie(c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(JSON.stringify(value)) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    }

    getCookie(c_name) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return JSON.parse(unescape(y));
            }
        }
    }

}