const script = require('./../script.json')
import './../css/fullpages.css'

export const arrAnimationComponents = [];

var animations = [];

// process script into animations
animations = script;
export function GetAnimations() {
    return animations
}

export function GetAnimationComponentById(id) {
    const result = arrAnimationComponents.find((aC) => {
        return (aC.id == id)
    })
    return result
}