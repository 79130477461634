import { AnimationComponent } from "./animation-component"
import { GetSVGImport } from "./../svg-imports.js"
import videojs from 'video.js'
export const videoJSPluginName = "tpm-plugin-kh5kl3zu-"


export class SvgAnimationComponent extends AnimationComponent {
    constructor(itemMetadata) {
        super(itemMetadata)
        appendVisual(itemMetadata, this.div)
    }

    TweenIn(data) {
        if (this.itemMetadata.pauseVideo == true) {
            var player = videojs(videoJSPluginName);
            player.pause()
        }

        super.TweenIn();
    }
    PlayVideo() {
        var player = videojs(videoJSPluginName);
        player.play();
    }

}

function appendVisual(itemMetadata, div) {
    const img = document.createElement("img")
    img.src = GetSVGImport(itemMetadata.object)
    div.className = "svgButton"
    div.id = itemMetadata.id
    div.appendChild(img)
    return div;

};