import { AnimationComponent } from "./animation-component"
import { GetHTMLImport } from './../html-imports'
import videojs from 'video.js'
export const videoJSPluginName = "tpm-plugin-kh5kl3zu-"
export class HtmlOverlayAnimationComponent extends AnimationComponent {
    constructor(itemMetadata) {
        super(itemMetadata)
        this.ContentIsLoaded = false
        this.div.className += this.div.className ? ' ' + itemMetadata.class : itemMetadata.class;
    }


    TweenIn() {
        if (this.itemMetadata.pauseVideo == true) {
            var player = videojs(videoJSPluginName);
            player.pause()
        }


        return new Promise((resolve, reject) => {

            const animationComponent = this

            console.log(`HTMLOverlay: ${this.id}, ContentIsLoaded: ${this.ContentIsLoaded}`)

            if (!this.ContentIsLoaded) {
                animationComponent.div.innerHTML = GetHTMLImport(this.itemMetadata.OverlayObject)
                this.ContentIsLoaded = true;
            }
            super.TweenIn()
            if (this.itemMetadata.id == "FinalScore")
                this.div.querySelector(".ScoreValue").innerHTML = window.score == null ? 0 + " / 12" : window.score + " / 12"

            resolve('Done')

        })
    }

    TweenOut(WasDismissedByApplication) {
        super.TweenOut(WasDismissedByApplication)
    }

    close() {
        var event = window.event;
        if (event.target.tagName != "A") {
            this.TweenOut();
            document.querySelector("video").play();
        }
    }
    fetchHTML(URL, animationComponent) {
        return fetch(URL).then(function(response) {
            return response.text()
        }).then(function(html) {
            const x = html_PeterTaylor
            eval("animationComponent.div.innerHTML = x")
            animationComponent.ContentIsLoaded = true
        })
    }


}