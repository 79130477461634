import { HtmlOverlayAnimationComponent } from "./html-overlay-animation-component"
import './../../css/quiz.css'
import videojs from 'video.js'
export const videoJSPluginName = "tpm-plugin-kh5kl3zu-"

export class QuizOptionsComponent extends HtmlOverlayAnimationComponent {
    constructor(itemMetadata) {
        super(itemMetadata)
        this.quizId = itemMetadata.quizId
        this.pauseTime = itemMetadata.pauseTime
        this.pollPreviouslySubmitted = false
        this.isClicked = [];
    }


    TweenIn() {
        if (!document.querySelector("video").classList.contains("shrinked")) {
            document.querySelector("video").classList += " shrinked";
        }
        super.TweenIn()
        var questionNumberDiv = this.div.querySelector(".questionNumber")
        var questionTextDiv = this.div.querySelector(".questionText")
        var questionOptionsList = this.div.querySelector(".questionOptionsList")
        var quizQuestionLoadContainerDiv = this.div.querySelector(".quizQuestionLoadContainer")
        quizQuestionLoadContainerDiv.style.display = "flex";
        var quizQuestionContentDiv = this.div.querySelector(".quizOptionsContent")
        quizQuestionContentDiv.style.display = "none";

        var that = this;
        fetch("https://interactive-eular.com/api/get-quiz-data-from-id?Id=" + this.quizId).then(response => response.json()).then((data) => {
            var spacePos = data.question.indexOf(' ');
            questionNumberDiv.innerHTML = "Question " + data.question.slice(0, spacePos);
            questionTextDiv.innerHTML = data.question.slice(spacePos, data.question.length);
            questionOptionsList.innerHTML = "";
            for (var i = 0; i < data.Options.length; i++) {
                var input = document.createElement('input');
                input.onclick = (e) => { that.changeBtnState(e) }
                input.type = "checkbox"
                input.id = that.quizId + "_" + data.Options[i];
                input.name = that.quizId + "_" + data.Options[i];
                input.value = data.Options[i];
                var label = document.createElement('label');
                label.setAttribute("for", that.quizId + "_" + data.Options[i]);
                label.innerHTML = (i + 1).toString() + ". " + data.Options[i];
                var br = document.createElement("br");
                questionOptionsList.appendChild(input)
                questionOptionsList.appendChild(label)
                questionOptionsList.appendChild(br)
                    //questionOptionsList.innerHTML += "<input type='checkbox' id='" + that.quizId + "_" + data.Options[i] + "' name='" + that.quizId + "_" + data.Options[i] + "' value='" + data.Options[i] + "'><label for='" + that.quizId + "_" + data.Options[i] + "'> " + data.Options[i] + "</label><br>"
            }
            quizQuestionLoadContainerDiv.style.display = "none";
            quizQuestionContentDiv.style.display = "flex";
        });

        this.div.querySelector('.submit_button').onclick = () => {
            window.lastVotedQuiz = that.quizId;
            document.querySelector("video").currentTime = that.pauseTime;
            that.TweenOut(true);
            var names = [];

            var formBody = [];
            var encodedKey = encodeURIComponent('Id');
            var encodedValue = encodeURIComponent(that.quizId);
            formBody.push(encodedKey + "=" + encodedValue);
            for (var i = 0; i < questionOptionsList.children.length; i++) {
                if (questionOptionsList.children[i].checked) {
                    var encodedKey = encodeURIComponent('names');
                    var encodedValue = encodeURIComponent(questionOptionsList.children[i].value);
                    formBody.push(encodedKey + "=" + encodedValue);
                    names.push(questionOptionsList.children[i].value)
                }
            }
            window["quizQuest_" + that.quizId] = names;
            formBody = formBody.join("&");
            fetch("https://interactive-eular.com/api/add-quiz-data-from-id", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            }).then(() => {
                fetch("https://interactive-eular.com/api/get-full-quiz-data-from-id?Id=" + that.quizId).then(response => response.json()).then((data) => {
                    var isCorrect = true;
                    for (var i = 0; i < data.Options.length; i++) {

                        if (data.Options[i].isCorrect) {
                            if (names.indexOf(data.Options[i].name) == -1) {
                                isCorrect = false;
                                break;
                            }
                        } else {
                            if (names.indexOf(data.Options[i].name) != -1) {
                                isCorrect = false;
                                break;
                            }
                        }
                    }
                    if (isCorrect) {
                        if (window.score == null) {
                            window.score = 1;
                        } else
                            window.score++
                    }
                });
            });
            document.querySelector(".vjs-control-bar").style.display = "flex";

        }
    }
    changeBtnState(e) {
        if (e.target.checked) {
            if (this.isClicked.indexOf(e.target.getAttribute("id")) == -1)
                this.isClicked.push(e.target.getAttribute("id"));
        } else {
            if (this.isClicked.indexOf(e.target.getAttribute("id")) != -1)
                this.isClicked.splice(this.isClicked.indexOf(e.target.getAttribute("id")), 1);
        }
        if (this.isClicked.length == 0) {
            if (this.div.querySelector('.submit_button').classList.contains("enabled")) {
                this.div.querySelector('.submit_button').classList.remove("enabled")
            }
        } else {
            if (!this.div.querySelector('.submit_button').classList.contains("enabled")) {
                this.div.querySelector('.submit_button').classList += " enabled";
            }
        }
    }
    TweenOut(SetWasDismissedByApplication) {
        if (document.querySelector("video").classList.contains("shrinked")) {
            document.querySelector("video").classList.remove("shrinked");
        }
        super.TweenOut(SetWasDismissedByApplication)
        var player = videojs(videoJSPluginName);
        player.play();
    }
    setCookie(c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(JSON.stringify(value)) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    }

    getCookie(c_name) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return JSON.parse(unescape(y));
            }
        }
    }

}