import { TweenMax } from "gsap";
//import {ParseIvEvents} from '../behaviours.js'
import { arrAnimationComponents } from './../animation-list'
import { videoJSPluginName } from './../main'


export class AnimationComponent {
    constructor(itemMetadata) {
        //console.log(`AnimationCompontent constructor for ${itemMetadata}`)
        this.itemMetadata = itemMetadata
        this.enlargeImage = itemMetadata.enlargeImage
        this.id = itemMetadata.id
        this.quizId = itemMetadata.quizId
        this.name = itemMetadata.name
        this.pollResultDisplayTime = itemMetadata.pollResultDisplayTime
        this.inTime = itemMetadata.in
        this.pauseTime = itemMetadata.pauseTime
        this.outTime = itemMetadata.out
        if (itemMetadata.dur != null) {
            const player = videojs(videoJSPluginName);
            console.log(player.cache_.currentTime);
            this.inTime += parseInt(player.cache_.currentTime)
            this.outTime = this.inTime + itemMetadata.dur
            console.log(this.outTime)
        }

        this.IsDisplayed = false
        this.WasDismissedByApplication = false
        this.OnlyThingOnScreen = itemMetadata.OnlyThingOnScreen
        this.LastzIndex = null;
        //this.animationComponentType = ""

        // look for div, if not exists make it
        var element = document.getElementById(itemMetadata.id);
        //If it isn't "undefined" and it isn't "null", then it exists.
        if (typeof(element) != 'undefined' && element != null) {
            //console.log(`Component with ID ${itemMetadata.id} exists so mapping it`)
            this.div = element
        } else {
            //console.log(`Component with ID ${itemMetadata.id} doesn't exist - creating it`)
            this.div = makeCoreObject(itemMetadata.coreObjectType, itemMetadata.name, itemMetadata.id)
            var targetParent = "customoverlay"
            if (itemMetadata.attachToDomAt != "default" && itemMetadata.attachToDomAt != '' && itemMetadata.attachToDomAt != null) {
                targetParent = itemMetadata.attachToDomAt
            }
            document.getElementById(targetParent).appendChild(this.div)
        }

        addStylesToHTMLObject(itemMetadata.initClasses, this.div)
        addBehaviours(this)
    }

    Animate(e) {
        const playerTime = e.detail
        if (this.WasDismissedByApplication == true && this.inTime == playerTime) this.WasDismissedByApplication = false
        if ((parseInt(playerTime) > parseInt(this.inTime)) && parseInt(playerTime) < parseInt(this.outTime) && this.pauseTime != null && parseInt(playerTime) > parseInt(this.pauseTime))
            this.PauseVideo();
        if ((this.pollResultDisplayTime != null && (parseInt(playerTime) > parseInt(this.pollResultDisplayTime) && parseInt(playerTime) < parseInt(this.outTime))) && !(this.IsDisplayed) && !(this.WasDismissedByApplication))
            this.TweenIn()
        else if ((((parseInt(playerTime) > parseInt(this.inTime)) && parseInt(playerTime) < parseInt(this.outTime))) &&
            !(this.IsDisplayed) &&
            !(this.WasDismissedByApplication)) {
            console.log(`${this.id} should tween in`)
            if (this.pollResultDisplayTime != null)
                this.TweenIn(true)
            else
                this.TweenIn()
        } else if (parseInt(playerTime) == parseInt(this.inTime) && (this.outTime == null) && !(this.IsDisplayed) && !(this.WasDismissedByApplication)) {
            console.log(`${this.id} should tween in 2`)
            this.TweenIn()
        }
        if ((parseInt(playerTime) > parseInt(this.inTime)) && parseInt(playerTime) < parseInt(this.outTime) && this.enlargeImage != null && parseInt(playerTime) > parseInt(this.enlargeImage))
            this.TweenIn({ enlarge: true });
        //this.id=="btn_IsThisForMe"?console.log(`In:${this.inTime}, Out:${this.outTime}, Displayed:${this.IsDisplayed}`):null
        if (((parseInt(playerTime) < this.inTime) || (parseInt(playerTime) > this.outTime)) && this.IsDisplayed) {
            //console.log("Should tween OUT")            
            this.TweenOut()
        }

    }

    HideAllSoloableControls() {
        if (this.OnlyThingOnScreen) {
            const hideables = arrAnimationComponents.filter((aC) => {
                return (aC.IsDisplayed == true && aC.OnlyThingOnScreen == true && aC.id != this.id)
            })
            if (hideables != null && hideables.length > 0) {
                hideables.forEach((hideable) => { hideable.TweenOut(false) })
            }
        }
    }

    PauseVideo() {
        if (window.lastVotedQuiz != null && window.lastVotedQuiz == this.quizId)
            return;
        const player = videojs(videoJSPluginName);
        player.pause();
        document.querySelector(".vjs-control-bar").style.display = "none";
    }

    PlayVideo(noOfSecondsToStartFrom) {
        const player = videojs(videoJSPluginName);
        if (noOfSecondsToStartFrom != null && noOfSecondsToStartFrom != '') player.currentTime(noOfSecondsToStartFrom)
        player.play();
    }

    LogToGoogle() {
        console.log('No log to Google')
    }

    TweenIn() {
        this.div.style.zIndex != null ? this.div.style.zIndex = this.LastzIndex : null
            //console.log(`Tweening In ${this.id}`)
        this.div.style.display = "block"
        if (this.itemMetadata != null && this.itemMetadata.tweenIn != null) {
            this.itemMetadata.tweenIn.forEach(element => {
                //console.log(`Tweening ${this.div.id} - ${JSON.stringify(element)}`)
                TweenMax.to(this.div,
                    (this.itemMetadata.tweenInSpeed == null || this.itemMetadata.tweenInSpeed == '') ? 1 : this.itemMetadata.tweenInSpeed, element)
            });
        }
        this.IsDisplayed = true
        this.LogToGoogle()
        this.HideAllSoloableControls()
    }

    TweenOut(SetWasDismissedByApplication) {
        this.LastzIndex = this.div.style.zIndex
            // console.log(`Tweening Out ${this.id}`)
            // this.id=="btn_IsThisForMe"?
            // console.log(`Parameter SetWasDismissedByapplication: ${SetWasDismissedByApplication}`)
            // :null
        if (this.itemMetadata != null && this.itemMetadata.tweenOut != null) {
            this.itemMetadata.tweenOut.forEach(element => {
                TweenMax.to(this.div,
                    (this.itemMetadata.tweenOutSpeed == null || this.itemMetadata.tweenOutSpeed == '') ? 1 : this.itemMetadata.tweenOutSpeed, element)
            });
        }
        this.IsDisplayed = false
        this.WasDismissedByApplication = SetWasDismissedByApplication == true ? true : false
    }

    ToggleDisplayed(SetWasDismissedByApplication) {
        this.IsDisplayed == true ? this.TweenOut(SetWasDismissedByApplication) : this.TweenIn()

    }

}

function makeCoreObject(type, name, id) {

    if (type == null || type == '') type = 'div'

    const newHtmlObject = document.createElement(type)
    newHtmlObject.id = id
    return newHtmlObject
}

function addClassesToHTMLObject(classes, div) {
    div.classList.add("test");
    if (classes == '' || classes == []) return
        //    classes.forEach(element => {
        //div.classlist.add(element)
        //div.addClass(element)    
        //    });
}

function addStylesToHTMLObject(styles, div) {
    if (styles == null || styles == '' || styles == []) return
    div.style = styles.join(';')
}

function addBehaviours(component) {
    if (component.itemMetadata != null && component.itemMetadata.ivEvents != null) {
        const ivEvents = ParseIvEvents(component);
    }
}

export function ParseIvEvents(component) {
    //onsole.log(`In ParseEvents for ${component.name}`)
    const onClick = "click"
    if (component.itemMetadata.ivEvents != null & component.itemMetadata.ivEvents.length > 0) {
        component.itemMetadata.ivEvents.forEach(ivEvent => {

            component.div.addEventListener(ivEvent.ivEventType, (e) => {

                ivEvent.ivEventActions.forEach((ivEventAction) => {
                    //console.log(ivEventAction);
                    const targetDivId = ivEventAction.targetDivId
                    const targetComponentExists = document.getElementById(targetDivId)
                    if (targetComponentExists == null || targetComponentExists == '') {
                        throw new Error(`Trying to run the ${ivEventAction.actionType} action of control ${component.id} targetting ${targetDivId} - the target does not exist in the DOM`)
                    }
                    const targetComponents = arrAnimationComponents.filter((AnimationComponent) => { return AnimationComponent.id == targetDivId })
                    targetComponents.forEach((targetComponent) => {
                        console.info(`Running event ${ivEventAction.actionType} to ${targetComponent.id}`)
                        targetComponent[ivEventAction.actionType](ivEventAction.parameter)
                    })

                })
            });
        })
    }
}