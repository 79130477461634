import { HtmlOverlayAnimationComponent } from "./html-overlay-animation-component"
import './../../css/quiz.css'


export class QuizOptionsResultComponent extends HtmlOverlayAnimationComponent {
    constructor(itemMetadata) {
        super(itemMetadata)
        this.quizId = itemMetadata.quizId
        this.pollPreviouslySubmitted = false
        this.fontSize = itemMetadata.fontSize;
        this.showSeeMyScore = itemMetadata.showSeeMyScore;

    }

    TweenIn() {
        if (this.getCookie("donot_show_prompts"))
            return;

        if (!document.querySelector("video").classList.contains("shrinked2")) {
            document.querySelector("video").classList += " shrinked2";
        }
        super.TweenIn()
        if (this.fontSize != null) {
            this.div.querySelector(".questionOptionsList").style.fontSize = this.fontSize + "px";
            this.div.querySelector(".questionText").style.fontSize = (this.fontSize + 2) + "px";
        }
        if (this.showSeeMyScore) {
            this.div.querySelector(".skip_button").innerHTML = "See my score&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&gt;"
        }
        var questionNumberDiv = this.div.querySelector(".questionNumber")
        var questionOptionsList = this.div.querySelector(".questionOptionsList")
        var quizQuestionLoadContainerDiv = this.div.querySelector(".quizQuestionLoadContainer")
        quizQuestionLoadContainerDiv.style.display = "flex";
        var quizOptionResultContDiv = this.div.querySelector(".quizOptionResultCont")
        quizOptionResultContDiv.style.display = "none";

        var that = this;
        fetch("https://interactive-eular.com/api/get-full-quiz-data-from-id?Id=" + this.quizId).then(response => response.json()).then((data) => {
            var spacePos = data.question.indexOf(' ');
            questionNumberDiv.innerHTML = "Question " + data.question.slice(0, spacePos);
            questionOptionsList.innerHTML = "";

            for (var i = 0; i < data.Options.length; i++) {
                var str = "<div class='singleQuestionOption'><div class='leftText'>";

                if (data.Options[i].isCorrect) {
                    str += '<div class="tick"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve"><polygon points="452.253,28.326 197.831,394.674 29.044,256.875 0,292.469 207.253,461.674 490,54.528 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></div>'
                    if (window["quizQuest_" + that.quizId] != null) {
                        if (window["quizQuest_" + that.quizId].includes(data.Options[i].name))
                            str += "<input type='checkbox' checked>"
                        else
                            str += "<input type='checkbox'>"
                        str += "<div class='OptionText' style='color:red;'>" + (i + 1).toString() + ". " + data.Options[i].name + "</div></div><div class='rightText'>"
                    } else {
                        str += "<div class='OptionText' style='color:red;'>" + (i + 1).toString() + ". " + data.Options[i].name + "</div></div><div class='rightText'>"

                    }
                } else {
                    str += '<div class="cross"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490 490" style="enable-background:new 0 0 490 490;" xml:space="preserve"><polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></div>'
                    if (window["quizQuest_" + that.quizId] != null) {
                        if (window["quizQuest_" + that.quizId].includes(data.Options[i].name))
                            str += "<input type='checkbox' checked>"
                        else
                            str += "<input type='checkbox'>"
                        str += "<div class='OptionText'>" + (i + 1).toString() + ". " + data.Options[i].name + "</div></div><div class='rightText'>"
                    } else {
                        str += "<div class='OptionText'>" + (i + 1).toString() + ". " + data.Options[i].name + "</div></div><div class='rightText'>"

                    }
                }
                var totalVote = data.Options[i].voteCount + data.Options[i].noVoteCount
                if (totalVote == 0) {
                    str += "<div class='scorebar' style='width:0%'></div>&nbsp;/&nbsp;<div class='scoreText'>0%</div></div>"

                } else {
                    if (data.Options[i].isCorrect) {
                        str += "<div class='scorebarCont'><div class='scorebar' style='width:" + (data.Options[i].voteCount / totalVote * 100).toFixed(0) + "%'></div></div><div class='scoreText'>" + (data.Options[i].voteCount / totalVote * 100).toFixed(0) + "%</div></div>"
                    } else
                        str += "<div class='scorebarCont'><div class='scorebar' style='width:" + (data.Options[i].noVoteCount / totalVote * 100).toFixed(0) + "%'></div></div><div class='scoreText'>" + (data.Options[i].noVoteCount / totalVote * 100).toFixed(0) + "%</div></div>"
                }
                questionOptionsList.innerHTML += str
            }

            quizQuestionLoadContainerDiv.style.display = "none";
            quizOptionResultContDiv.style.display = "flex";
        });
        this.div.querySelector('.skip_button').onclick = () => {
            that.TweenOut(true);
        }

    }

    TweenOut(SetWasDismissedByApplication) {
        if (!SetWasDismissedByApplication) {
            document.querySelector("video").pause();
            document.querySelector(".vjs-control-bar").style.display = "none";
            return;
        }
        if (document.querySelector("video").classList.contains("shrinked2")) {
            document.querySelector("video").classList.remove("shrinked2");
        }
        document.querySelector(".vjs-control-bar").style.display = "flex";

        document.querySelector("video").currentTime = this.itemMetadata.out;
        document.querySelector("video").play();
        super.TweenOut(SetWasDismissedByApplication)
    }
    setCookie(c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(JSON.stringify(value)) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    }

    getCookie(c_name) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x == c_name) {
                return JSON.parse(unescape(y));
            }
        }
    }

}