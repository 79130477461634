import { SvgAnimationComponent } from './animationcomponents/svg-component'
import { HtmlOverlayAnimationComponent } from './animationcomponents/html-overlay-animation-component'
import { QuizQuestionComponent } from './animationcomponents/QuizQuestionComponent'
import { QuizOptionsComponent } from './animationcomponents/QuizOptionsComponent'
import { QuizAnswersComponent } from './animationcomponents/QuizAnswersComponent'
import { QuizOptionsResultComponent } from './animationcomponents/QuizOptionsResultComponent'

// Move this to animation list
export const svgButton = "svgButton"
export const htmlOverlay = "htmlOverlay"
export const quizQuestion = 'quizQuestion'
export const quizOptions = 'quizOptions'
export const quizAnswers = 'quizAnswers'
export const quizOptionResult = 'quizOptionResult'

export function CreateAnimationComponent(itemMetadata) {

    if (itemMetadata.type == svgButton) {
        return new SvgAnimationComponent(itemMetadata)
    }
    if (itemMetadata.type == htmlOverlay) {
        return new HtmlOverlayAnimationComponent(itemMetadata)
    }
    if (itemMetadata.type == quizQuestion) {
        //console.log('SVG')
        return new QuizQuestionComponent(itemMetadata)
    }
    if (itemMetadata.type == quizOptions) {
        //console.log('SVG')
        return new QuizOptionsComponent(itemMetadata)
    }
    if (itemMetadata.type == quizAnswers) {
        //console.log('SVG')
        return new QuizAnswersComponent(itemMetadata)
    }
    if (itemMetadata.type == quizOptionResult) {
        //console.log('SVG')
        return new QuizOptionsResultComponent(itemMetadata)
    }
}