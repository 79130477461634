import videojs from 'video.js'
export const videoJSPluginName = "tpm-plugin-kh5kl3zu-"
import { GetAnimations, arrAnimationComponents } from './animation-list'
import { CreateAnimationComponent } from './animation-component-factory'
var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
        var res = JSON.parse(xhttp.response)
        var url = res.videoSources.html["480p"][0].source;
        var exptime = parseInt(url.substring(url.indexOf("exp=") + 4, url.indexOf("~")))
        var now = new Date().getTime();
        var diff = exptime * 1000 - now
        setTimeout(() => {
            var xhttp2 = new XMLHttpRequest();
            xhttp2.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    var res2 = JSON.parse(xhttp2.response)
                    document.querySelector("#tpm-plugin-kh5kl3zu-").innerHTML += '<source src="' + res2.videoSources.html["480p"][0].source + '" type="video/mp4"><source src="' + res2.videoSources.html.auto[0].source + '" type="application/vnd.apple.mpegurl">'
                }
            }
            xhttp2.open("GET", "https://d.video-cdn.net/play/player/5v3C6R396vqoznNCsBYwCi/video/Ehmkb2zYfS5UabhYR9TWjM", true);
            xhttp2.send();
        }, diff - 5000);
        document.querySelector("#tpm-plugin-kh5kl3zu-").innerHTML += '<source src="' + res.videoSources.html["480p"][0].source + '" type="video/mp4"><source src="' + res.videoSources.html.auto[0].source + '" type="application/vnd.apple.mpegurl">'
        var myPlayer = videojs(videoJSPluginName);
        var previousTime = 0;
        var currentTime = 0;
        var seekStart = null;

        myPlayer.on('timeupdate', function() {
            previousTime = currentTime;
            currentTime = myPlayer.currentTime();
        });

        myPlayer.on('seeking', function() {
            if (seekStart === null) {
                seekStart = previousTime;
            }
        });

        myPlayer.on('seeked', function() {
            if (currentTime < seekStart && currentTime != 0) {
                myPlayer.currentTime(seekStart);
            }
            seekStart = null;
        });
        myPlayer.on('ended', function() {
            myPlayer.currentTime(0);
        });
        myPlayer.on('ready', function() {

            changeButtons();
            myPlayer.on('play', () => {
                if (document.querySelector("#master").getAttribute("data-interacted") != "completed") {
                    myPlayer.pause();
                    myPlayer.muted(false);
                    document.querySelector("#master").setAttribute("data-interacted", "completed");
                }
            });

            myPlayer.ready(function() {
                //idsabling the double tap to fullscreen event on the player
                myPlayer.tech_.off('dblclick');
            });

            const logtimerevents = false;
            var globalCurrentTime = 0;
            myPlayer.on('timeupdate', function() {
                logtimerevents ? console.log(`In standalone on.'timeupdate'`) : null
                const localcurrentTime = Math.round(myPlayer.currentTime())
                if (Math.round(localcurrentTime) != globalCurrentTime) {
                    globalCurrentTime = localcurrentTime;
                    var event = new CustomEvent("AnimationTimerTick", { "detail": globalCurrentTime });
                    document.dispatchEvent(event);
                }
            })
            myPlayer.play()

        }); //end ready
    }
};
xhttp.open("GET", "https://d.video-cdn.net/play/player/5v3C6R396vqoznNCsBYwCi/video/Ehmkb2zYfS5UabhYR9TWjM", true);
xhttp.send();
const arrAnimationComponentMetadataList = GetAnimations();
arrAnimationComponentMetadataList.forEach((item) => {
    const newAnimationComponent = CreateAnimationComponent(item);
    if (newAnimationComponent != null) {
        arrAnimationComponents.push(newAnimationComponent)
    }
    //newAnimationComponent.div.addEventListener('AnimationTimerTick', ()=>{console.log('Heard It')})
});
document.addEventListener('AnimationTimerTick', AnimationTimerTick, false)
var event = new CustomEvent("AnimationTimerTick", { "detail": 0 });
document.dispatchEvent(event);

function AnimationTimerTick(e) {
    arrAnimationComponents.forEach((animationComponent) => {
        animationComponent.Animate(e)
    })
    e.stopPropagation()
}

window.XMovePlayheadToAndPlay = function(time, panel) {
    var player = videojs(videoJSPluginName);
    player.currentTime(time);
    (document.getElementById(panel)).ToggleOut()
    player.play();
}

function changeButtons() {
    var el = document.querySelector('.vjs-fullscreen-control'),
        elClone = el.cloneNode(true);
    //replacing the bull screen button to remove all the players' event
    el.parentNode.replaceChild(elClone, el);

    //adding the new fullscreen event
    document.querySelector('.vjs-fullscreen-control').addEventListener("click", fullscreenVideo);
}
//New fullscreen event that will replace the current fullscreen event on the players' button
function fullscreenVideo() {
    const masterDiv = document.getElementById("master")

    if (!masterDiv.classList.contains("fullscreened")) {
        //full screening the master div instead of just the player
        document.querySelector("#outercont").requestFullscreen();
        masterDiv.classList += " fullscreened";
    } else {
        document.exitFullscreen();
        masterDiv.classList.remove("fullscreened");
    }
}